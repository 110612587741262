import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import TextareaWithLabel from '../textarea'
import useMedRequestAction from '@/lib/api/med-requests/action'
import { cn, fieldValidationNotifications } from '@/lib/utils'
import LoadingSpinner from '@/components/ui/icons/loading-spinner'
import SingleCombobox from '@/components/common/single-combobox'
import { useState } from 'react'
import useApproveMedicationRequest from '@/lib/api/med-requests/approve'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'

const ApprovalReasonSection = () => {
  const { justification, setJustification, setSidebarView } = useMedicationRequestStore()

  const approveMedicationRequest = useApproveMedicationRequest()
  const action = useMedRequestAction()
  const [template, setTemplate] = useState('')
  const { data } = useCurrentMedication()

  const { templateOptions } = useQuestionnaire(data.questionaire)

  return (
    <div className='sticky top-[88px] h-fit flex min-w-[25vw] flex-col rounded-2xl border border-custom-neutral-200 shadow-[0px_0px_1px_0px_rgba(113,128,150,0.04),_0px_4px_8px_0px_rgba(113,128,150,0.08)]'>
      <div className='p-6'>
        <p className='mb-2 text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>Approval Reason</p>
        <p className='text-custom-neutral-500 text-sm leading-[170%]'>
          Provide the reasons for approving the medication request.
        </p>
      </div>
      <div className='p-6'>
        <p className='mb-4 text-[#1D1D1D] font-medium leading-[170%]'>Select a template</p>
        <SingleCombobox
          title='Templates'
          isDisabled={templateOptions?.approvalOptions.length === 0}
          defaultValue={template}
          onValueChange={value => {
            setJustification(value)
            setTemplate(value)
          }}
          options={templateOptions?.approvalOptions ?? []}
        />
        <div className='mt-4 mb-12'>
          <TextareaWithLabel value={justification} onChange={e => setJustification(e.target.value)} autoFocus />
        </div>
        <button
          type='button'
          onClick={() => {
            if (!fieldValidationNotifications('approve')) return
            approveMedicationRequest()
            setJustification('')
            setSidebarView('navigation-menu')
          }}
          disabled={!justification || action.isPending}
          className={cn(
            'flex justify-center items-center gap-2 mb-4 h-14 rounded-2xl bg-[#153CB7] text-white text-sm font-semibold leading-[170%] w-full transition-all duration-200 ease-in-out',
            !justification && 'opacity-50 cursor-not-allowed',
            action.isPending && 'cursor-wait opacity-70'
          )}
        >
          {action.isPending ? (
            <>
              <LoadingSpinner className='w-6 h-6 text-white animate-spin' />
              <span>Loading..</span>
            </>
          ) : (
            'Submit'
          )}
        </button>
        <button
          type='button'
          onClick={() => {
            setJustification('')
            setSidebarView('navigation-menu')
          }}
          className='flex h-14 w-full color-[#1D1D1D] items-center justify-center gap-4 rounded-2xl border border-custom-neutral-200 bg-white px-6 text-custom-neutral-600 text-sm font-semibold  hover:bg-custom-neutral-200 transition-colors duration-200
        leading-[170%]'
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default ApprovalReasonSection
