import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import useMedRequestAction from './action'
import useBatchApproval from '@/hooks/useBatchApproval'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'

export default function useApproveMedicationRequest() {
  const { medication, medicationIssueDate } = useMedicationRequestStore()
  const goNextMedRequest = useGoNextMedRequest()

  const { data: originalMedRequest } = useCurrentMedication()

  const { parsedQuestions } = useQuestionnaire(originalMedRequest.questionaire)
  const hasFlags = parsedQuestions?.some(question => question.hasFlags)

  // Temporarily disable batch approvals.
  // const { data: batchApprovals } = useBatchApproval({
  //   medRequestId: originalMedRequest.id,
  //   enabled: !hasFlags
  // })

  const action = useMedRequestAction({
    // doNotResetStore: (batchApprovals?.length && !hasFlags)
  })

  return () => {
    let changeProduct = undefined
    if (
      typeof medication?.wooSKU === 'string' &&
      typeof originalMedRequest?.wooSKU === 'string' &&
      medication.wooSKU !== originalMedRequest?.wooSKU
    ) {
      changeProduct = {
        prevSKU: originalMedRequest?.wooSKU,
        newSKU: medication.wooSKU
      }
    }

    const { notes, medicationDirection, setStatus, setBatchOrders, justification, setJustification } =
      useMedicationRequestStore.getState()
    action.mutate({
      id: originalMedRequest.id,
      action: 'approve',
      reason: justification,
      approveDate: medicationIssueDate?.toISOString(),
      additionalNotes: notes,
      extraDirectionsForMedication: medicationDirection,
      changeProduct
    })

    // Move to batch approval screen.
    // if (batchApprovals?.length && !hasFlags) {
    //   setBatchOrders(batchApprovals)
    //   setStatus('approved')
    // } else {
    goNextMedRequest()
    setJustification('')
    // }
  }
}
