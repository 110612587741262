import http from '@/lib/http'
import type { MedRequest } from '@/types/data'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { queryClient } from './queryClient'
import { useParams } from 'react-router-dom'

export const useTotalMedicationRequests = () => {
  return useSuspenseQuery<{
    beforeCutOff: number
    total: number
  }>({
    queryKey: ['totalMedicationRequests'],
    queryFn: () => http.get('/prescriber/med-requests/total'),
    refetchInterval: 30000
  })
}

export const useSuspenseMedicationRequestById = (id: string) => {
  return useSuspenseQuery<MedRequest>({
    queryKey: ['medicationRequestById', id],
    queryFn: () => http.get(`/prescriber/med-requests/${id}`),
    staleTime: 0,
    refetchOnMount: true
  })
}

export const useMedicationRequestById = (id: string, enabled?: boolean) => {
  return useQuery<MedRequest>({
    queryKey: ['medicationRequestById', id],
    queryFn: () => http.get(`/prescriber/med-requests/${id}`),
    enabled
  })
}

export const useLockMedicationById = (id: string, enabled: boolean) => {
  const { id: currentMedId } = useParams<{ id: string }>()

  return useQuery({
    queryKey: ['lockMedicationById', id],
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 10,
    queryFn: async () => {
      const success = await http.post<boolean, boolean>(`/prescriber/med-requests/${id}/lock`)
      if (!success) {
        queryClient.invalidateQueries({ queryKey: ['medRequestFiltering'] })
        if (currentMedId === id) {
          queryClient.invalidateQueries({ queryKey: ['medicationRequestById', id] })
        }
        return true
      }
    },
    enabled
  })
}
