import type { AdditionalNotesType, BatchMedRequest, Medication } from '@/types/data'
import { create } from 'zustand'

export type SCRData = {
  date: string
  observation?: string
  medication_time?: string
  dosage?: string
  quantity?: string
}

type SidebarView = 'navigation-menu' | 'contact-patient' | 'rejection' | 'approval-reason'

type Status = 'pending' | 'approved' | 'rejected' | 'on-hold'

interface MedicationRequestStore {
  batchOrders: BatchMedRequest[]
  setBatchOrders: (orders: BatchMedRequest[]) => void
  medication: Medication | null
  setMedication: (medication: Medication | null) => void
  medicationDirection: string
  setMedicationDirection: (medicationDirection: string) => void
  medicationIssueDate: Date
  setMedicationIssueDate: (issueDate: Date) => void
  justification: string
  setJustification: (justification: string) => void
  notes: AdditionalNotesType
  setNotes: (notes: AdditionalNotesType) => void
  sidebarView: SidebarView
  setSidebarView: (sidebarView: SidebarView) => void
  status: Status
  setStatus: (status: Status) => void
  selectedBatchOrderIds: string[]
  setSelectedBatchOrderIds: (items: string[]) => void
  rejectionReason: string
  setRejectionReason: (rejectionReason: string) => void
  contactMessage: string
  setContactMessage: (contactMessage: string) => void
}

export const useMedicationRequestStore = create<MedicationRequestStore>(set => ({
  batchOrders: [],
  setBatchOrders: (orders: BatchMedRequest[]) => set({ batchOrders: orders }),
  medication: null,
  setMedication: (medication: Medication | null) => set({ medication }),
  medicationDirection: '',
  setMedicationDirection: (medicationDirection: string) => set({ medicationDirection }),
  medicationIssueDate: new Date(),
  setMedicationIssueDate: (issueDate: Date) => set({ medicationIssueDate: issueDate }),
  justification: '',
  setJustification: (justification: string) => set({ justification }),
  notes: {
    pharmacist: '',
    customerService: ''
  },
  setNotes: (notes: AdditionalNotesType) => set({ notes }),
  sidebarView: 'navigation-menu',
  setSidebarView: (sidebarView: SidebarView) => set({ sidebarView }),
  status: 'pending',
  setStatus: (status: Status) => set({ status }),
  selectedBatchOrderIds: [],
  setSelectedBatchOrderIds: (items: string[]) => set({ selectedBatchOrderIds: items }),
  rejectionReason: '',
  setRejectionReason: (rejectionReason: string) => set({ rejectionReason }),
  contactMessage: '',
  setContactMessage: (contactMessage: string) => set({ contactMessage })
}))

export const resetMedicationRequestStore = () =>
  useMedicationRequestStore.setState({
    ...useMedicationRequestStore.getInitialState(),
    medicationIssueDate: new Date()
  })
