import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import { MedicationRequestInfo } from '@/components/common/medicationrequestinfo'
import { useUser } from '@clerk/clerk-react'
import { Suspense } from 'react'
import Filter from './filter'
import SearchInput from '@/components/ui/search-input'
import { useFilterStore } from '@/lib/stores/filter'

const { setFilteringOptions } = useFilterStore.getState()

export default function DashboardPage() {
  const { user } = useUser()
  const { filteringOptions } = useFilterStore()
  const resetSearchFiltering = () => {
    const { filteringOptions } = useFilterStore.getState()
    setFilteringOptions({
      ...filteringOptions,
      'Order Number': []
    })
  }

  return (
    <section className='flex flex-col gap-8'>
      <div className='flex items-start justify-between'>
        <div className='flex flex-col gap-2'>
          <h2 className='capitalize text-[2rem] font-semibold leading-10 text-secondary'>
            Welcome {user?.fullName || user?.primaryEmailAddress?.emailAddress}
          </h2>

          <p className='leading-6 text-sm font-normal text-custom-neutral-500'>
            Use filters to prioritize medication requests that need action.
          </p>
        </div>

        <SearchInput
          value={filteringOptions['Order Number'][0] || ''}
          onChange={searchQuery => {
            if (searchQuery === '') {
              resetSearchFiltering()
            } else {
              setFilteringOptions({
                'ID Verified Time': [],
                'Shipping Method': [],
                'Order Number': [searchQuery],
                priority: false,
                onHolds: false
              })
            }
          }}
          onCancel={resetSearchFiltering}
        />
      </div>

      <ErrorBoundary fallback='Error loading totals'>
        <Suspense>
          <MedicationRequestInfo />
        </Suspense>
      </ErrorBoundary>

      <Filter />
    </section>
  )
}
